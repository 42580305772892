<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="예비품 목록"
      tableId="equipmentInsp"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      selection="multiple"
      rowKey="spareSeq"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn v-if="editable" label="추가" :showLoading="true" icon="add" @btnClicked="add" />
            <c-btn v-if="editable && isDelete" label="삭제" :showLoading="true" icon="remove" @btnClicked="remove" />
            <c-btn
              v-if="editable"
              :url="insertUrl"
              :isSubmit="isSave"
              :param="grid.data"
              mappingType="POST"
              label="저장"
              icon="save"
              @beforeAction="saveSparePart"
              @btnCallback="saveCallback" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "spare-parts",

    data() {
    return {
      grid: {
        columns: [
          {
            required: true,
            name: "plantCd",
            field: "plantCd",
            label: "사업장",
            align: "center",
            style: 'width:80px',
            type: 'plant',
            sortable: true,
          },
          {
            required: true,
            name: "spareName",
            field: "spareName",
            label: "예비품명",
            style: 'width:150px',
            align: "left",
            type: 'text',
            sortable: true,
          },
          {
            name: "sparePurpose",
            field: "sparePurpose",
            label: "용도",
            style: 'width:150px',
            align: "left",
            type: 'text',
            sortable: true,
          },
          {
            name: "spareVolume",
            field: "spareVolume",
            label: "용량",
            style: 'width:150px',
            type: 'text',
            align: "left",
            sortable: true,
          },
          {
            name: "sparePower",
            field: "sparePower",
            label: "동력",
            align: "left",
            style: 'width:100px',
            type: 'text',
            sortable: true,
          },
          {
            name: "registeredQuantity",
            field: "registeredQuantity",
            label: "등록수량",
            align: "center",
            type: 'number',
            style: 'width:60px',
            sortable: true,
          },
          {
            name: "vendorCd",
            field: "vendorCd",
            label: "제조회사",
            align: "left",
            style: 'width:120px',
            type: 'text',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        spareNm: null,
        purpose: null,
        manuCompany: null,
      },
      editable: true,
      listUrl: '',
      insertUrl: '',
      updateUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isSave: false,
      isDelete: false,
      num: 0,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.equipment.spareParts.list.url;
      this.insertUrl = transactionConfig.mdm.equipment.spareParts.insert.url;
      this.deleteUrl = transactionConfig.mdm.equipment.spareParts.delete.url;
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (_result.data.length > 0) this.isDelete = true;
        this.grid.data = _result.data;
      },);
    },
    add() {
      this.grid.data.splice(0, 0, {
        spareSeq: uid(),
        plantCd: null, 
        spareNm: '', 
        sparePurpose: '', 
        spareVolume: '',
        sparePower: '', 
        registeredQuantity: '',
        vendorCd: '',
        editFlag: 'C',
      })
    },
    saveSparePart() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          selectData = this.$_.reject(selectData, item => {
            return !item.spareSeq
          })
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.saveCallback();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "예비품 상세"; // 예비품 상세
      this.popupOptions.param = {
        row: row,
      };
      this.popupOptions.target = () =>
        import(`${"./sparePartsDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.height = '300px';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    save() {

    },
    onItemClick() {
      this.popupOptions.target = () =>
      import(`${"./sparePartsDetail.vue"}`);
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.title = '예비품 상세'; // 설비점검계획 상세
      this.popupOptions.param = null;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
  },
};
</script>
